<template>
  <div class="content">
    <form class="form-view grid no-gutters row" autocomplete="off">
      <div :class="formColumnClasses">
        <div
          v-for="(name, section) of data.Data.Sections"
          :key="name"
          class="row no-gutters"
        >
          <div class="col-xs-12 col-md-3">
            <div class="section-title">{{ section.Title }}</div>
            <div class="section-description">{{ section.Description }}</div>
          </div>
          <div class="col-xs-12 col-md-9">
            <div class="row"></div>
          </div>
          <div class="col-12">
            <hr class="w-100 mt-5 mb-5" />
          </div>
        </div>
      </div>
      <div v-if="!hideSidebar" id="form-sidebar"></div>
    </form>
    <!-- END -->
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hideSidebar: function () {
      return this.$store.state.settings.HideSideBar;
    },
    formColumnClasses: function () {
      if (!this.hideSidebar) {
        return {
          "col-md-8": true,
          "col-xs-12": true,
        };
      }

      return {"col-xs-12": true};
    },
  },
};
</script>

<style></style>
